import './App.css';
import NavbarComponent from './navbar';
import FooterComponent from './footer';
import Banner from './banner';
import Skills from './skills';
import Mission from './mission';
import Resume from './resume';
import Contact from './contact';
import FAQ from './faq';
import Services from './services';
import ReviewCarousel from './review-carousel';

import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
        <NavbarComponent />
        <Banner />
        <div className='spacing'></div>

        <div className='tutors-section'>
          <img className='tutors-logo' src="tutors-logo.png" alt="tutors.com logo" />
          <span className='vertical-align-middle'>&nbsp;<b>Tutors.com Top 10 Tutors in Ashburn, VA</b></span>
        </div>

        <ReviewCarousel />

        <div className='button-container'>
          <a href='https://docs.google.com/forms/d/e/1FAIpQLSfhpyBNrL7r9GWCLS0-AwdbCivFb-O9CaVDHBZ1NjV3sBxFNQ/viewform?usp=sf_link'><Button size="lg" variant="primary">Book an Appointment</Button></a>
        </div>

        <Resume />
        <div className='large-spacing'></div>
        <Skills />
        <div className='large-spacing'></div>
        <Mission />
        <div className='large-spacing'></div>
        <Services />
        <div className='large-spacing'></div>
        <FAQ />
        <div className='large-spacing'></div>
        <Contact />
        <div className='large-spacing'></div>
        <div className='large-spacing'></div>
        <FooterComponent />
    </div>
  );
}

export default App;
